body {
  margin: 0;
  padding: 0;

  font-family: PingFang SC;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:global input:focus{
  border: 0 !important;
  outline: none!important;
}
:global textarea:focus{
  border: 0 !important;
  outline: none!important;
}
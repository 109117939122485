body {
  margin: 0;
  padding: 0;

  font-family: PingFang SC;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input:focus{
  border: 0 !important;
  outline: none!important;
}
textarea:focus{
  border: 0 !important;
  outline: none!important;
}
header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 4vw;
  line-height: 4vw;
  display: flex;
  justify-content: center;
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
}
header.show-shadow {
  background: #FDFDFD;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header .headerWrapper {
  position: relative;
  width: 100vw;
  height: 7.3vw;
  padding: 0 auto;
  padding-top: 4.2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@-webkit-keyframes scaleAnimation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 初始状态，无放大效果 */
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  /* 放大到原来的1.5倍 */
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 返回原来的大小 */
}
@keyframes scaleAnimation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 初始状态，无放大效果 */
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  /* 放大到原来的1.5倍 */
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 返回原来的大小 */
}
header .headerWrapper .logo {
  height: 7.8vh;
  -webkit-animation: scaleAnimation 3.5s;
          animation: scaleAnimation 3.5s;
}
header .headerWrapper .headerBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8vw;
}
header .headerWrapper .headerBtn .button {
  position: relative;
  cursor: pointer;
  color: #333333;
  width: 6vw;
  margin: 0 0.8vw;
  text-align: center;
  font-size: 1.3vw;
  line-height: 2vw;
  height: 2vw;
  border-width: 0;
  font-family: Impact;
  background-color: transparent;
  box-shadow: 0 0 0;
  font-weight: 600;
}
header .headerWrapper .headerBtn .button:hover {
  border-bottom: 1px solid #1E7C51;
  color: #1E7C51;
}
header .headerWrapper .headerBtn .active-btn {
  color: #1E7C51;
  border-width: 0;
  font-family: Impact;
  border-bottom: 1px solid #1E7C51;
  box-shadow: 0 0 0;
}
header .headerWrapper .headerBtn .active-btn:hover {
  color: #333;
}
header .btn-white {
  position: relative;
  background: #fedb67;
  padding: 0 18vw 0;
}

.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
.layout {
  position: fixed;
  height: 100vh;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #F5F5F7 !important;
}
.layout .banner .bgWrapper {
  height: 100% !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-left: 17vw;
  padding-top: 15vh;
  padding-right: 49vw;
  background-image: url(../../static/media/agbg.6158604d.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.layout .banner .bgWrapper .content .title {
  font-size: 3.2vh !important;
  line-height: 4vh!important;
  height: auto!important;
  margin-bottom: 3vh;
}
.layout .banner .bgWrapper .content .subtitle,
.layout .banner .bgWrapper .content .desc {
  margin-top: 1vh !important;
  font-size: 1.6vh !important;
  line-height: 2vh!important;
  height: auto!important;
}
.layout .banner .bgWrapper .content .banner-btn {
  width: auto!important;
  margin-top: 7vw!important;
  font-size: 1.5vh !important;
  border-radius: 0.8vh;
  height: 4.4vh;
}
.layout .banner .bgWrapper .content .banner-btn .banner-img {
  height: 5.2vh !important;
}
.layout .banner .bgWrapper .imgWrapper img {
  position: relative;
  height: 55vh !important;
}
.layout .footer {
  position: fixed!important;
  z-index: 2!important;
  bottom: 0!important;
  height: 20vh !important;
  font-family: Impact;
  font-weight: 400;
  color: #AAAAAA;
  background: rgba(245, 245, 247, 0.39);
}
.layout .footer .bar {
  padding: 0 !important;
  justify-content: center;
  display: block !important;
  padding: 0 22vw !important;
}
.layout .footer .bar .one-center {
  display: flex;
}
.layout .footer .bar .one-center .infos {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}
.layout .footer .bar .one-center-end {
  margin-left: 0 !important;
  padding-top: 4vh;
}
.layout .footer .bar .one-center-end .infos {
  justify-content: flex-start !important;
}
.layout .footer .bar .title {
  width: 10vw;
  font-size: 2.2vh !important;
  text-align: left;
  font-family: Impact;
  font-weight: 400;
  color: #7780FD;
}
.layout .footer .bar .infos {
  text-align: left;
  padding-bottom: 4vh !important;
  margin-bottom: 0 !important;
  font-size: 1.5vh !important;
  font-family: Impact;
  font-weight: 400;
  color: #AAAAAA !important;
}
.layout .footer .bar .infos a {
  text-align: left;
  margin-right: 1.5vw;
  font-size: 1.5vh !important;
  text-decoration: initial;
}
.layout .footer .bar .infos div {
  color: #AAAAAA !important;
}
.layout .footer .bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2vw;
  line-height: 2vw;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.features img {
  margin-top: 0 !important;
}
.block {
  position: relative;
}
.banner {
  position: relative;
  margin: auto;
  width: 100vw;
  background-size: 100vw auto;
  height: 80vh;
  overflow: hidden;
}
.banner .bgWrapper {
  position: relative;
  margin: 0 auto;
  margin-left: 19vw;
  margin-right: 16vw;
  text-align: left;
  display: flex;
}
.banner .bgWrapper .content {
  position: relative;
  z-index: 1;
  text-align: left;
  margin: 0;
  padding-left: 1.6vw;
  padding-top: 5.5vw;
}
.banner .bgWrapper .content .title {
  font-family: PT Sans Narrow;
  font-weight: bold;
  font-size: 2.5vh;
  font-family: Impact;
  font-weight: 400;
  line-height: 3.3vw;
  color: #FFFFFF;
}
.banner .bgWrapper .content .subtitle {
  margin-top: 4vw;
  font-size: 1.3vw;
  font-family: PT Sans Narrow;
  font-weight: bold;
  line-height: 1.7vw;
  color: #ffffff;
  height: 3.4vw;
}
.banner .bgWrapper .content .desc {
  margin-top: 1vw;
  font-size: 1.3vw;
  font-family: PT Sans Narrow;
  font-weight: bold;
  line-height: 1.7vw;
  color: #ffffff;
  height: 3.4vw;
}
.banner .bgWrapper .content .banner-btn {
  margin-top: 7.8vh;
  margin-bottom: 2.6vw;
  height: 4.7vw;
  background: rgba(255, 255, 255, 0);
  font-size: 1.2vw;
  font-family: PT Sans Narrow;
  font-weight: bold;
  color: #1D2F33;
  opacity: 1;
  border: 0 !important;
  overflow: hidden;
}
.banner .bgWrapper .content .banner-btn a {
  color: #1D2F33;
  text-decoration: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
@-webkit-keyframes text-slide-up {
  from {
    -webkit-transform: translateX(5vh);
            transform: translateX(5vh);
  }
  to {
    -webkit-transform: translateX(0vh);
            transform: translateX(0vh);
  }
}
@keyframes text-slide-up {
  from {
    -webkit-transform: translateX(5vh);
            transform: translateX(5vh);
  }
  to {
    -webkit-transform: translateX(0vh);
            transform: translateX(0vh);
  }
}
.banner .bgWrapper .content .banner-btn .banner-img {
  position: absolute;
  z-index: 1;
  left: 2vw;
  height: 0.9vw;
  -webkit-animation-name: text-slide-up;
          animation-name: text-slide-up;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  /* 2s */
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.banner .bgWrapper .content .banner-btn .banner-txt {
  position: relative;
  z-index: 0;
  margin-left: 2.6vh;
  padding-left: 3vh;
  padding-right: 1vh;
  height: 3.5vh;
  line-height: 3.5vh;
  background: #f5f5f7;
  opacity: 1;
  border-radius: 2vw;
  font-family: Impact;
  font-weight: 400;
  color: #71A2FF;
}
@-webkit-keyframes slide-up {
  from {
    -webkit-transform: translate(30%, 70%);
            transform: translate(30%, 70%);
  }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@keyframes slide-up {
  from {
    -webkit-transform: translate(30%, 70%);
            transform: translate(30%, 70%);
  }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
.banner .bgWrapper .imgWrapper {
  position: absolute;
  left: 54vw;
  top: 25vh;
  margin: 0 auto;
  text-align: center;
}
.banner .bgWrapper .imgWrapper img {
  height: 26.2vw;
  -webkit-animation-name: slide-up;
          animation-name: slide-up;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  /* 2s */
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.banner-ims {
  position: absolute;
  left: -3px;
  top: 0;
  width: 100%;
  height: 150px;
  z-index: 3;
}
.banner-andriod {
  width: 17px;
  height: 20px;
  margin: -5px 10px 0 5px;
}
.banner-newIcon {
  width: 47px;
  height: 47px;
  position: absolute;
  top: -16px;
  right: -8px;
  z-index: 999;
}
.banner-word-box {
  width: 480px;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.banner-word-download {
  width: 275px;
  position: relative;
  height: 110px;
  color: #fff;
  display: flex;
}
.banner-word-download svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-download div {
  position: absolute;
  top: 24px;
  left: 12px;
  z-index: 4;
  color: #fff;
  font-family: Impact;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-word-downloads {
  margin-top: 30px;
  width: 237px;
  position: relative;
  height: 150px;
  color: #fff;
  display: flex;
}
.banner-word-downloads svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-downloads div {
  position: absolute;
  top: 24px;
  left: 20px;
  z-index: 4;
  color: #fff;
  font-family: Impact;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.banner-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-text-wrapper {
  width: 40%;
  padding-right: 114px;
  height: 265px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-text-wrapper h1 {
  font-size: 46px;
}
.banner-text-wrapper p {
  font-size: 16px;
  margin: 50px 0 60px;
  font-weight: 300;
}
.andriod {
  width: 17px;
  height: 18px;
}

.footer {
  overflow: hidden;
  width: 100vw;
}
.footer .bar {
  background: rgba(245, 245, 247, 0.39);
  opacity: 1;
  padding: 2.6vw 24vw;
  font-family: Impact;
  font-weight: 400;
  color: #AAAAAA;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.footer .bar .one-center {
  font-family: PingFang SC;
  font-weight: 600;
  position: relative;
}
.footer .bar .one-center .title {
  padding: 0;
  margin: 0;
  font-size: 1vw;
  line-height: 1vw;
  margin-bottom: 1vw;
}
.footer .bar .one-center .infos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3.2vw;
  font-size: 0.8vw;
}
.footer .bar .one-center .infos .contImg {
  width: 1.1vw;
  height: 0.9vw;
  margin-right: 0.7vw;
  fill: #262626;
}
.footer .bar .one-center-end {
  text-align: right;
}
.footer .bar .one-center-end .infos {
  text-align: right;
  justify-content: flex-end;
}
.footer .bar .one-center-end .infos a {
  font-family: Impact;
  font-weight: 400;
  color: #AAAAAA;
  text-align: right;
  font-family: PingFang SC;
  font-weight: 600;
}
.footer .bar .one-center-end .infos:last-child {
  margin-bottom: 0;
}
.footer .bottom-bar {
  overflow: hidden;
  height: 3.3vw;
  line-height: 3.3vw;
  text-align: center;
  color: #333333;
  font-size: 0.7vw;
  font-family: PingFang SC;
  font-weight: 400;
}

.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
.layout {
  position: fixed;
  height: 100vh;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #F5F5F7 !important;
}
.layout .banner .bgWrapper {
  height: 100% !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-left: 17vw;
  padding-top: 15vh;
  padding-right: 49vw;
  background-image: url(../../static/media/agbg.6158604d.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.layout .banner .bgWrapper .content .title {
  font-size: 3.2vh !important;
  line-height: 4vh!important;
  height: auto!important;
  margin-bottom: 3vh;
}
.layout .banner .bgWrapper .content .subtitle,
.layout .banner .bgWrapper .content .desc {
  margin-top: 1vh !important;
  font-size: 1.6vh !important;
  line-height: 2vh!important;
  height: auto!important;
}
.layout .banner .bgWrapper .content .banner-btn {
  width: auto!important;
  margin-top: 7vw!important;
  font-size: 1.5vh !important;
  border-radius: 0.8vh;
  height: 4.4vh;
}
.layout .banner .bgWrapper .content .banner-btn .banner-img {
  height: 5.2vh !important;
}
.layout .banner .bgWrapper .imgWrapper img {
  position: relative;
  height: 55vh !important;
}
.layout .footer {
  position: fixed!important;
  z-index: 2!important;
  bottom: 0!important;
  height: 20vh !important;
  font-family: Impact;
  font-weight: 400;
  color: #AAAAAA;
  background: rgba(245, 245, 247, 0.39);
}
.layout .footer .bar {
  padding: 0 !important;
  justify-content: center;
  display: block !important;
  padding: 0 22vw !important;
}
.layout .footer .bar .one-center {
  display: flex;
}
.layout .footer .bar .one-center .infos {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}
.layout .footer .bar .one-center-end {
  margin-left: 0 !important;
  padding-top: 4vh;
}
.layout .footer .bar .one-center-end .infos {
  justify-content: flex-start !important;
}
.layout .footer .bar .title {
  width: 10vw;
  font-size: 2.2vh !important;
  text-align: left;
  font-family: Impact;
  font-weight: 400;
  color: #7780FD;
}
.layout .footer .bar .infos {
  text-align: left;
  padding-bottom: 4vh !important;
  margin-bottom: 0 !important;
  font-size: 1.5vh !important;
  font-family: Impact;
  font-weight: 400;
  color: #AAAAAA !important;
}
.layout .footer .bar .infos a {
  text-align: left;
  margin-right: 1.5vw;
  font-size: 1.5vh !important;
  text-decoration: initial;
}
.layout .footer .bar .infos div {
  color: #AAAAAA !important;
}
.layout .footer .bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2vw;
  line-height: 2vw;
}
#reat-content {
  font-family: Impact;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 3.7vw;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.bodyMob {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  background: #F5F5F7 !important;
}
.bodyMob .btn-white {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
}
.banner-mobile {
  position: relative;
}
.block-mobile {
  position: relative;
}
.header-mobile {
  position: relative;
}
.headerWrapper-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.headerWrapper-mobile .logo {
  margin-top: 3vh;
  height: 10vh;
}
.headerWrapper-mobile .popover-menu {
  width: 300px;
}
.headerWrapper-mobile .popover-menu button.lang {
  margin: 4.2vw auto;
  float: none;
}
.headerWrapper-mobile .popover-menu div.version {
  margin: 8.5vw auto 4.2vw;
  float: none;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner {
  padding: 0;
  overflow: hidden;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner-content {
  padding: 0;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul li span {
  color: #2972FB;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul li::selection {
  color: #fff;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul .ant-menu-item-selected {
  color: #fff;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul .ant-menu-item::after {
  border-right: 1vw solid #064852 !important;
}
.headerWrapper-mobile .nav-phone-menu {
  fill: #333333;
  width: 5.3vw;
  height: 5.3vw;
  cursor: pointer;
}
.banner-mobile {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 0;
  height: 100vh;
  overflow-x: hidden;
}
.banner-mobile .bgWrapper {
  background-size: 100% ;
  position: relative;
  margin: 0 auto;
  text-align: left;
  height: 100%;
  padding: 0 1.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../static/media/agbg.6158604d.png);
  background-position: center;
  background-size: auto 100% ;
  background-repeat: no-repeat;
}
@-webkit-keyframes title-slide-up {
  from {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
  }
  to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes title-slide-up {
  from {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
  }
  to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
.banner-mobile .bgWrapper .content {
  margin-top: 30vh;
  padding: 0 3.5vw;
  position: relative;
  z-index: 1;
  height: 100%;
  padding-top: 1px;
  color: #FFFFFF;
}
.banner-mobile .bgWrapper .content .title {
  position: relative;
  padding-top: 1.2vw;
  font-size: 4.2vw;
  line-height: 6vw;
  font-weight: bold;
  margin-left: 2vw;
  -webkit-animation-name: title-slide-up;
          animation-name: title-slide-up;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  /* 2s */
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.banner-mobile .bgWrapper .content .subtitle {
  margin-left: 2vw;
  position: relative;
  padding-top: 4.6vw;
  font-size: 3.3vw;
  font-weight: 400;
  line-height: 4.5vw;
  margin-bottom: 0.9vw;
}
.banner-mobile .bgWrapper .content .desc {
  margin-left: 2vw;
  font-size: 3.3vw;
  font-family: PT Sans Narrow;
  font-weight: 400;
  line-height: 4.5vw;
  margin-bottom: 4.6vw;
}
.banner-mobile .bgWrapper .content .word-download {
  margin-top: 21px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.banner-mobile .bgWrapper .content .banner-btn {
  position: relative;
  text-align: center;
  margin-left: 2vw;
  height: 10vw;
  border: 0;
  background: rgba(255, 255, 255, 0);
  opacity: 1;
  border-radius: 2.1vw;
  font-size: 4vw;
  font-family: PT Sans Narrow;
  font-weight: bold;
  line-height: 4.6vw;
  margin-bottom: 3.5vw;
  margin-top: 15vh;
  display: flex;
  align-items: center;
}
.banner-mobile .bgWrapper .content .banner-btn a {
  text-decoration: initial;
  color: #262626;
  display: flex;
  align-items: center;
}
.banner-mobile .bgWrapper .content .banner-btn .banner-img {
  position: absolute;
  left: 0vh;
  width: 6.2vh;
  height: 6.2vh;
  margin-right: 0.3vw;
}
.banner-mobile .bgWrapper .content .banner-btn .banner-txt {
  display: block;
  background: #f5f5f7;
  opacity: 1;
  border-radius: 21px;
  font-family: Impact;
  font-weight: 400;
  color: #71A2FF;
  margin-left: 0;
  padding: 5px 14px;
  padding-left: 6vh;
}
.banner-mobile .bgWrapper .imgWrapper {
  position: absolute;
  z-index: 0;
  margin-top: 14px;
  bottom: 8vh;
  right: -22vh;
  text-align: center;
}
.banner-mobile .bgWrapper .imgWrapper img {
  height: 300px;
}
.banner-mobile-ims {
  position: absolute;
  left: -0.8vw;
  top: 0;
  width: 100%;
  height: 40vw;
  z-index: 3;
}
.banner-mobile-andriod {
  width: 4.5vw;
  height: 5.3vw;
  margin: -1.3vw 2.6vw 0 1.3vw;
}
.banner-mobile-newIcon {
  width: 12.5vw;
  height: 12.5vw;
  position: absolute;
  top: -4.2vw;
  right: -2.1vw;
  z-index: 999;
}
.banner-mobile-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.banner-mobile-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-mobile-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-mobile-text-wrapper {
  width: 40%;
  padding-right: 13.7vw;
  height: 70.6vw;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-mobile-text-wrapper h1 {
  font-size: 46px;
}
.banner-mobile-text-wrapper p {
  font-size: 4.2vw;
  margin: 13.3vw 0 16vw;
  font-weight: 300;
}
@-webkit-keyframes footer-slide-up {
  from {
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
  }
  to {
    -webkit-transform: translate(0%);
            transform: translate(0%);
  }
}
@keyframes footer-slide-up {
  from {
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
  }
  to {
    -webkit-transform: translate(0%);
            transform: translate(0%);
  }
}
.footer-mobile {
  overflow: hidden;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 4.5vw;
  padding-top: 0;
  margin-top: 0px;
  background: rgba(245, 245, 247, 0.9);
  margin: 0;
  -webkit-animation-name: footer-slide-up;
          animation-name: footer-slide-up;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  /* 2s */
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.footer-mobile .one-center {
  padding: 3.5vw 3.5vw;
  border-radius: 21px;
  font-family: PingFang SC;
  font-weight: 400;
  position: relative;
  text-align: left;
}
.footer-mobile .one-center .title {
  font-size: 4.5vw;
  font-weight: bold;
  line-height: 5.3vw;
  color: #7780FD;
  opacity: 1;
}
.footer-mobile .one-center .infos {
  height: 4.4vw;
  opacity: 1;
  border-radius: 1.8vw;
  margin: 0 auto;
  font-size: 3.4vw;
  font-weight: 400;
  line-height: 6.4vw;
  color: #AAAAAA;
  margin-top: 2.6vw;
}
.footer-mobile .one-center .infos a {
  color: #AAAAAA;
  text-align: left;
  font-family: PingFang SC;
  font-weight: 600;
}
.footer-mobile .one-center-end {
  margin-top: 2.1vw;
  opacity: 1;
  text-align: left;
  padding: 3.5vw 3.5vw;
}
.footer-mobile .one-center-end .title {
  font-size: 4vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4vw;
  color: #7780FD;
}
.footer-mobile .one-center-end .infos {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2.6vw;
}
.footer-mobile .one-center-end .infos div {
  height: 4vw;
  line-height: 4vw;
  font-size: 3.4vw;
  font-family: PingFang SC;
  font-weight: 500;
  color: #AAAAAA;
}
.footer-mobile .one-center-end .infos .contImg {
  position: relative;
  width: 3.7vw;
  height: 2.6vw;
  margin-right: 1.8vw;
  margin-top: 1vw;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #064852;
  color: #ffffff;
}
.ant-menu-item::after {
  border-right: 1vw solid #064852 !important;
}

.contentWrapper {
  background: #fff;
  padding: 20px;
  width: 800px;
  margin: auto;
}
.contentWrapper h1 {
  font-size: 24px;
  text-align: center;
  font-family: Impact;
  font-weight: 400;
  color: #333333;
  font-weight: 500;
}
.contentWrapper h2 {
  font-size: 24px;
  text-align: center;
  color: #AAAAAA;
  font-weight: 500;
}
.contentWrapper p {
  text-align: left;
  text-indent: 30px;
  font-size: 16px;
  line-height: 30px;
  color: #AAAAAA;
}
@media only screen and (max-width: 830px) {
  .contentWrapper {
    background: #fff;
    padding: 20px;
    width: 100%;
    margin: auto;
    border: 0;
    box-sizing: border-box;
  }
  .contentWrapper h2 {
    font-size: 24px;
    text-align: center;
    color: #262626;
    font-weight: 500;
  }
  .contentWrapper p {
    text-align: left;
    text-indent: 30px;
    font-size: 15px;
    line-height: 30px;
    color: #262626;
  }
}

.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  width: 100%;
  justify-content: center!important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  justify-content: center!important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0!important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0!important;
  padding: 1vh 6vw!important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: red;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto!important;
  line-height: initial!important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed!important;
  left: 0!important;
  right: 0!important;
  z-index: 100!important;
  bottom: 35vh!important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center!important;
  margin: 0 auto!important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh!important;
  line-height: 4vh;
}
.deleteWrapper-mob {
  padding: 35px 0;
  padding-top: 108px;
  position: relative;
  min-height: 100vh;
  background-color: #F5F5F7;
}
.deleteWrapper-mob .tabNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 90vw;
  z-index: 100;
  z-index: 0;
  background: linear-gradient(180deg, #8C8CFE 0%, #506EFE 100%);
}
.deleteWrapper-mob .tabNav .title {
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0;
  padding-top: 8.7vw;
  font-size: 3.9vw;
}
.deleteWrapper-mob .content {
  position: fixed;
  top: 18vw;
  left: 4.5vw;
  z-index: 1;
  right: 4.5vw;
  border-radius: 0 0 2.8vw 2.8vw;
}
.deleteWrapper-mob .tip {
  position: relative;
  padding: 3vw 0;
  margin: 0 28vw;
  text-align: center;
  font-size: 5.6vw;
  font-family: Roboto;
  font-weight: bold;
  color: #71A2FF;
  background: #f5f5f7;
  letter-spacing: 0px;
  border-radius: 2.8vw 2.8vw 0px 0px;
}
.deleteWrapper-mob .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 25px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 38px;
  color: #71A2FF;
  text-align: left;
  padding: 0 35px;
}
.deleteWrapper-mob .fbtInfo {
  position: relative;
  opacity: 1;
  border-radius: 21px;
  background-color: #F5F5F7;
  border-radius: 3.2vw;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  padding: 9.3vw 4.6vw;
}
.deleteWrapper-mob .fbtCol {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 42px;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #71A2FF;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}
.deleteWrapper-mob .fbtCol .need {
  color: #71A2FF;
}
.deleteWrapper-mob .fbtPhone {
  background: #f5f5f7;
  padding: 2.2vw 0;
  opacity: 1;
  width: 100%;
  margin-bottom: 4.6vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  margin-left: 4.6vw;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput {
  position: relative;
  width: 100%;
  font-family: Roboto;
  font-weight: bold;
  caret-color: #71A2FF;
  color: #333;
  background-color: rgba(255, 255, 255, 0);
  letter-spacing: 0px;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.3vw;
  margin-left: 2.8vw;
  border: 0;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  color: #CCC;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.3vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::placeholder {
  color: #CCC;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.3vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpIdCard {
  padding-left: 4.6vw;
  margin-left: 0;
}
.deleteWrapper-mob .fbtPhone .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
}
.deleteWrapper-mob .fbtPhone .showTips span {
  font-size: 2.2vw;
  color: #b92d00;
}
.deleteWrapper-mob .fbtPhone::-webkit-input-placeholder {
  color: #CCC;
}
.deleteWrapper-mob .fbtPhone::placeholder {
  color: #CCC;
}
.deleteWrapper-mob .itemSelect {
  padding: 0 4.6vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f7;
  opacity: 1;
  padding-top: 2.2vw;
  padding-bottom: 2.2vw;
  font-size: 3.3vw;
  margin-bottom: 4.6vw;
}
.deleteWrapper-mob .itemSelect .iSelect {
  border: 0;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  height: 5vw;
  line-height: 5vw;
  color: #333;
  width: 100%;
  background: initial;
}
.deleteWrapper-mob .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper-mob .itemSelect .iSelect::-webkit-input-placeholder {
  color: #CCC;
}
.deleteWrapper-mob .itemSelect .iSelect::placeholder {
  color: #CCC;
}
.deleteWrapper-mob .itemSelect .itemImg {
  width: 4.6vw;
  height: 4.6vw;
  cursor: pointer;
}
.deleteWrapper-mob .fbtContent {
  width: 100%;
  padding: 2.2vw 4.6vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  opacity: 1;
  background: #f5f5f7;
  box-sizing: border-box;
  border: 0;
}
.deleteWrapper-mob .fbtContent .fpLabel {
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper-mob .fbtContent .fpText {
  border: 0 !important;
  font-weight: bold;
  color: #333;
  background-color: #f5f5f7;
  caret-color: #CCC;
  width: 100%;
  height: 18vw;
  font-size: 3.3vw;
}
.deleteWrapper-mob .fbtContent .fpText::-webkit-input-placeholder {
  color: #CCC;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
}
.deleteWrapper-mob .fbtContent .fpText::placeholder {
  color: #CCC;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
}
.deleteWrapper-mob .itemSelect::-webkit-input-placeholder {
  color: #CCC;
}
.deleteWrapper-mob .itemSelect::placeholder {
  color: #CCC;
}
.deleteWrapper-mob .fbtContentOn {
  border: 2px solid #71a2ff;
  opacity: 1;
}
.deleteWrapper-mob .idCard {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper-mob .agreeWrapper {
  margin-top: 5.9vw;
  margin-bottom: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.deleteWrapper-mob .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #7780fd;
  width: 3.6vw;
  height: 3.6vw;
}
.deleteWrapper-mob .agreeWrapper .hasCheck {
  background-color: #7780fd;
}
.deleteWrapper-mob .agreeWrapper .hasCheck .laImg {
  position: absolute;
  top: 0.3vw;
  width: 3.6vw;
  height: 2.7vw;
}
.deleteWrapper-mob .agreeWrapper .laText {
  display: flex;
  margin-left: 2.6vw;
  color: #000;
  letter-spacing: 0px;
  font-size: 2.5vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 2.5vw;
  cursor: pointer;
}
.deleteWrapper-mob .agreeWrapper .laText .normolColor {
  color: #333333;
}
.deleteWrapper-mob .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #333333;
  font-weight: bold;
  padding: 0 0.1vw;
}
.deleteWrapper-mob .fbtAction {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 11vw;
  height: 9.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7780fd;
  opacity: 0.7;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  margin: 0 8.3vw;
  border-radius: 5.6vw;
}
.deleteWrapper-mob .fbtActionOn {
  background: #7780fd;
  opacity: 1;
}
.deleteWrapper-mob .fbtSuccess {
  position: relative;
  display: flex;
  height: 100vh;
  margin: 0 auto;
  padding: 0 3.5vw;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
}
.deleteWrapper-mob .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor-mob {
  padding-top: 0;
  background-color: #f7f7f7;
  color: #71a2ff;
}
.deleteWrapperColor-mob .tabNav {
  height: 100vh;
}
.copySuccessTip-mob {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip-mob .iconsmile {
  width: 54px;
  height: 54px;
  fill: #71a2ff;
  margin-right: 9px;
}
.copySuccessTip-mob span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #71a2ff;
  opacity: 1;
}
.edit-mob .content {
  overflow: hidden;
  height: 70vh !important;
  border-radius: 21px;
}
.edit-mob .content .tip {
  display: none;
}
.edit-mob .content .fbtInfo {
  display: block;
  height: 100%;
  padding-bottom: 80vw;
  overflow-y: scroll;
}
.selectModal-mob .modalContent {
  border-radius: 2.8vw 2.8vw 0px 0px;
  margin: 0 4.6vw;
}
.selectModal-mob .modalContent .listHead {
  font-size: 3.9vw;
}
.selectModal-mob .modalContent .list .listItem {
  font-size: 3.9vw;
  margin: 0 4.6vw;
  border-bottom: 1px dashed #333;
}
.selectModal-mob .modalContent .list .listItemOn {
  border-bottom: 0;
}

.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  width: 100%;
  justify-content: center!important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  justify-content: center!important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0!important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0!important;
  padding: 1vh 6vw!important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: red;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto!important;
  line-height: initial!important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed!important;
  left: 0!important;
  right: 0!important;
  z-index: 100!important;
  bottom: 35vh!important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center!important;
  margin: 0 auto!important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh!important;
  line-height: 4vh;
}
.deleteWrapper {
  padding: 2.1vh;
  padding-top: 8vh;
  position: relative;
  min-height: 100vh;
  background-color: #F5F5F7;
}
.deleteWrapper .tabNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70vh;
  z-index: 1;
  background: linear-gradient(180deg, #8C8CFE 0%, #506EFE 100%);
}
.deleteWrapper .tabNav .title {
  padding-top: 3.5vh;
  font-size: 2.6vh;
  font-family: Roboto;
  font-weight: bold;
  text-align: center;
  color: #fff;
  letter-spacing: 0px;
}
.deleteWrapper .content {
  position: fixed;
  z-index: 1;
  top: 10vh;
  height: 90vh;
  left: 5vw;
  right: 5vw;
}
.deleteWrapper .tip {
  position: relative;
  padding: 3vh 0;
  margin: 0 30vw;
  text-align: center;
  font-size: 3vh;
  font-family: Roboto;
  font-weight: bold;
  line-height: 3.5vh;
  color: #71A2FF;
  letter-spacing: 0px;
  background: #f5f5f7;
  opacity: 1;
  border-radius: 28px 28px 0px 0px;
}
.deleteWrapper .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 2.3vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 2.5vh;
  color: #71A2FF;
  text-align: left;
}
.deleteWrapper .fbtInfo {
  position: relative;
  opacity: 1;
  padding: 6.9vh 3.5vh;
  border-radius: 2.4vh;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
.deleteWrapper .fbtCol {
  position: relative;
  padding-top: 1.4vh;
  padding-bottom: 1.2vh;
  font-size: 2.2vh;
  line-height: 2.5vh;
  font-family: Roboto;
  font-weight: 400;
  color: #71A2FF;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  padding-left: 4.5vh;
}
.deleteWrapper .fbtCol .need {
  color: #71A2FF;
}
.deleteWrapper .fbtPhone {
  background: #f5f5f7;
  padding: 1vh 0;
  opacity: 1;
  width: 100%;
  margin-bottom: 5vh;
}
.deleteWrapper .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  margin-left: 2.2vw;
  font-size: 2vh;
  height: 2.2vh;
  line-height: 2.2vh;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput {
  position: relative;
  width: 100%;
  font-family: Roboto;
  font-weight: bold;
  caret-color: #71A2FF;
  color: #333;
  background-color: rgba(255, 255, 255, 0);
  letter-spacing: 0px;
  height: 3.2vh;
  line-height: 3.2vh;
  font-size: 2vh;
  margin-left: 1.1vw;
  border: 0;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  color: #CCC;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::placeholder {
  color: #CCC;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .fbtPhone .fpInfo .fpIdCard {
  padding-left: 2.2vw;
  margin-left: 0;
}
.deleteWrapper .fbtPhone .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
}
.deleteWrapper .fbtPhone .showTips span {
  font-size: 2vh;
  color: #b92d00;
}
.deleteWrapper .fbtPhone .showTips img {
  margin-left: 2.2vw;
  width: 2vh;
  height: 2vh;
}
.deleteWrapper .fbtPhone::-webkit-input-placeholder {
  color: #CCC;
}
.deleteWrapper .fbtPhone::placeholder {
  color: #CCC;
}
.deleteWrapper .itemSelect {
  padding: 0 2.2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f7;
  opacity: 1;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: 2vh;
  margin-bottom: 5vh;
}
.deleteWrapper .itemSelect .iSelect {
  border: 0;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  height: 3.2vh;
  line-height: 3.2vh;
  color: #333;
  width: 100%;
  background: initial;
}
.deleteWrapper .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper .itemSelect .iSelect::-webkit-input-placeholder {
  color: #CCC;
}
.deleteWrapper .itemSelect .iSelect::placeholder {
  color: #CCC;
}
.deleteWrapper .itemSelect .itemImg {
  width: 2.2vh;
  height: 2.2vh;
  cursor: pointer;
}
.deleteWrapper .fbtContent {
  width: 100%;
  padding: 1vh 2.2vw;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  opacity: 1;
  background: #f5f5f7;
  box-sizing: border-box;
  border: 0;
}
.deleteWrapper .fbtContent .fpLabel {
  font-size: 21px;
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper .fbtContent .fpText {
  border: 0 !important;
  font-weight: bold;
  color: #333;
  background-color: #f5f5f7;
  caret-color: #CCC;
  padding-top: 0.1vh;
  width: 100%;
  height: 10vh;
  font-size: 2vh;
}
.deleteWrapper .fbtContent .fpText::-webkit-input-placeholder {
  color: #CCC;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .fbtContent .fpText::placeholder {
  color: #CCC;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .itemSelect::-webkit-input-placeholder {
  color: #CCC;
}
.deleteWrapper .itemSelect::placeholder {
  color: #CCC;
}
.deleteWrapper .fbtContentOn {
  border: 2px solid #71a2ff;
  opacity: 1;
}
.deleteWrapper .idCard {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper .agreeWrapper {
  margin: 0 auto;
  margin-top: 2.2vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.deleteWrapper .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #7780fd;
  width: 2.4vh;
  height: 2.4vh;
  margin-left: 8px;
  margin-top: 2px;
  margin-right: 2.1vh;
}
.deleteWrapper .agreeWrapper .hasCheck {
  margin-left: 8px;
  margin-top: 2px;
  background-color: #7780fd;
}
.deleteWrapper .agreeWrapper .hasCheck .laImg {
  position: absolute;
  left: 0;
  top: 0;
  width: 2.4vh;
  height: 1.8vh;
}
.deleteWrapper .agreeWrapper .laText {
  display: flex;
  margin-left: 1.1vh;
  color: #000;
  letter-spacing: 0px;
  font-size: 2.1vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.1vh;
  cursor: pointer;
}
.deleteWrapper .agreeWrapper .laText .normolColor {
  color: #333333;
}
.deleteWrapper .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #333333;
  font-weight: bold;
  padding: 0 1.1vw;
}
.deleteWrapper .fbtAction {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: bold;
  background: #7780fd;
  bottom: 2vh;
  height: 6.8vh;
  opacity: 0.7;
  font-size: 3.3vh;
  color: #fff;
  letter-spacing: 0px;
  margin: 0 10.4vh;
  border-radius: 2.1vh;
}
.deleteWrapper .fbtActionOn {
  opacity: 1;
}
.deleteWrapper .fbtSuccess {
  position: relative;
  display: flex;
  height: 90vh;
  z-index: 100;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 4.8vh;
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
}
.deleteWrapper .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor {
  background-color: #71A2FF;
  color: #71a2ff;
  padding-top: 0;
}
.deleteWrapperColor .tabNav {
  height: 100vh;
}
.copySuccessTip {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip .iconsmile {
  width: 54px;
  height: 54px;
  fill: #71a2ff;
  margin-right: 9px;
}
.copySuccessTip span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #71a2ff;
  opacity: 1;
}
.edit {
  height: auto;
  padding-bottom: 40vh;
}
.list {
  max-height: 60vh;
  overflow: scroll;
  text-align: center;
}
.list .am-list-content {
  overflow: initial !important;
  text-overflow: initial !important;
  white-space: initial!important;
  font-size: 2.1vh !important;
  padding-top: 1.2vh !important;
  padding-bottom: 1.2vh !important;
}
.list .am-radio-input {
  height: 5vw;
}
.list .am-list-item {
  min-height: 4vh!important;
}
.list .am-radio {
  height: 4vh!important;
}
.list .am-radio-inner {
  right: 0.5vh !important;
  top: 2vw!important;
  width: 2.2vh;
  height: 2.2vh;
}
.list .am-radio-inner:after {
  width: 1.2vh;
  height: 2.2vh;
}
.list .listItem {
  text-align: center;
}
.list .listItemTip {
  font-size: 2.1vh;
  color: #999999;
  text-align: center;
}
.list .listItemTip .listItemTSign {
  color: #B92D00;
}
.selectModal {
  position: fixed;
  left: 0 ;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: flex-end;
}
.modalContent {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 42px 42px 0 0;
  background: #fff;
  margin: 0 3.5vh;
}
.modalContent .listHead {
  background: linear-gradient(180deg, #8C8CFE 0%, #506EFE 100%);
  font-weight: bold;
  color: #FFFFFF;
  border-bottom: 1px solid #71A2FF;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
  height: 8vh;
  line-height: 8vh;
  font-size: 2.2vh;
  font-family: Roboto;
}
.modalContent .listHead .icon {
  width: 35px;
  height: 35px;
}
.modalContent .list {
  position: relative;
  background-color: #fff;
  overflow: auto;
  text-align: center;
  max-height: 60vh;
}
.modalContent .list .listItem {
  position: relative;
  margin: 0 69px;
  text-align: center;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  padding: 1.5vh 0;
  border-bottom: 1px dashed #333;
}
.modalContent .list .listItemOn {
  color: #fff;
  background-color: #71a2ff;
  border-bottom: 0;
  font-weight: bold;
}
.modalContent .list .listItemOn .listItem {
  position: relative;
  color: #fff;
}
.modalContent .list::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 40px;
}
.modalContent .list::-webkit-scrollbar-button {
  color: #333333;
}
.modalContent .list::-webkit-scrollbar-thumb {
  background-position: center;
  border-radius: 10px;
  height: 40px;
  background-color: blue;
}

